<template>
    <div class="change-password">
        <div class="block-title">
            <div class="dot" />
            {{ $t('user.ChangePassword.title') }}
        </div>
        <div class="form-data">
            <v-form
                ref="form"
                v-model="valid"
            >
                <v-text-field
                    v-model="oldPassword"
                    :type="showOldPWD ? 'text' : 'password'"
                    :append-icon="showOldPWD ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    :label="$t('user.ChangePassword.label[0]')"
                    placeholder=""
                    :hint="$t('user.ChangePassword.hint[0]')"
                    persistent-hint
                    :rules="passwordRule"
                    class="border-orange-input"
                    @click:append="showOldPWD = !showOldPWD"
                />
                <v-text-field
                    v-model="newPassword"
                    :type="showNewPWD ? 'text' : 'password'"
                    :append-icon="showNewPWD ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    :label="$t('user.ChangePassword.label[1]')"
                    placeholder=""
                    :hint="$t('user.ChangePassword.hint[0]')"
                    persistent-hint
                    :rules="passwordRule"
                    class="border-orange-input"
                    @click:append="showNewPWD = !showNewPWD"
                />
                <v-text-field
                    v-model="rePassword"
                    :type="showRePWD ? 'text' : 'password'"
                    :append-icon="showRePWD ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    :label="$t('user.ChangePassword.label[2]')"
                    placeholder=""
                    :hint="$t('user.ChangePassword.hint[0]')"
                    persistent-hint
                    :rules="rePasswordRule"
                    class="border-orange-input"
                    @click:append="showRePWD = !showRePWD"
                />
            </v-form>
            <div class="action">
                <div
                    class="btn cancel-btn color-white"
                    @click="cleanAll"
                >
                    {{ $t('user.ChangePassword.clean') }}
                </div>
                <div
                    class="btn bg-orange color-white"
                    @click="changePassword"
                >
                    {{ $t('user.ChangePassword.save') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import API from '@/api';

export default {
    components: {},
    data() {
        return {
            valid: false,
            oldPassword: '',
            newPassword: '',
            rePassword: '',
            showOldPWD: false,
            showNewPWD: false,
            showRePWD: false,
            passwordRule: [
                (v) => !!v || this.$t('user.ChangePassword.rule[0]'),
                (v) => (v && v.length >= 6) || this.$t('user.ChangePassword.rule[1]'),
                (v) => !v.match(/\s/) || this.$t('user.ChangePassword.rule[2]'),
            ],
            rePasswordRule: [
                (v) => !!v || this.$t('user.ChangePassword.rule[0]'),
                (v) => v === this.newPassword || this.$t('user.ChangePassword.rule[3]'),
            ],
        };
    },
    computed: {
        ...mapState('mAuth', {
            account: (state) => state.data?.account,
        }),
    },
    created() {},
    mounted() {},
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        ...mapMutations('mLoading', ['setLoading']),
        ...mapActions('mAuth', ['Logout']),
        changePassword() {
            this.$refs.form.validate();
            if (this.valid) {
                const form = {
                    oldPassword: this.oldPassword,
                    password: this.newPassword,
                };
                this.setLoading(true);
                API.User.ChangePassword(form)
                    .then((res) => {
                        console.log(res.data);
                        this.setAlert({
                            message: this.$t('alertMessage.update-success'),
                            color: 'success',
                        });
                        this.Logout();
                        this.$router.push({ name: 'Home' });
                        this.setLoading(false);
                    })
                    .catch((e) => {
                        this.handleError({
                            message: e || this.$t('alertMessage.update-fail'),
                            color: 'error',
                        });
                        this.setLoading(false);
                    });
            }
        },
        cleanAll() {
            this.oldPassword = '';
            this.newPassword = '';
            this.rePassword = '';
        },
    },
};
</script>

<style lang="scss" scoped>
.change-password{
  .form-data{
    width: 95%;
    max-width: 600px;
  }
}

</style>
